<template>
  <div class="custom-form__block">
    <div class="custom-form__inputBlock">
      <p class="custom-form__form-label">Электронное получение услуги</p>
      <ComponentInput
        v-model="stepDto.serviceUrl"
        maxLength="255"
        mod="rectangular"
        placeholder="Введите ссылку на внешний ресурс, предоставляющий услугу"
      />
    </div>

    <hr />
    <h3 class="custom-form__title">Регуляторы и организации</h3>

    <h3 class="custom-form__title">Регуляторы</h3>
    <CardListItems
      :listItems="regulators"
      :options="getOrganizationList"
      @add="addRegulators"
      @delete="(index) => deleteRegulators(index)"
    />

    <hr />
    <h3 class="custom-form__title">Участники</h3>
    <CardListItems
      :listItems="participants"
      :options="getOrganizationList"
      @add="addParticipant"
      @delete="(index) => deleteParticipant(index)"
    />

    <hr />
    <div>
      <DocumentItem
        header="Результаты"
        :documents="stepDto.stepResultDocumentIds"
        :options="getDocumentList"
        @add="addItem('stepResultDocumentIds')"
        @delete="(index) => deleteItem(index, 'stepResultDocumentIds')"
      />
      <DocumentItem
        header="Требуемые документы"
        :documents="stepDto.stepRequiredDocumentIds"
        :options="getDocumentList"
        @add="addItem('stepRequiredDocumentIds')"
        @delete="(index) => deleteItem(index, 'stepRequiredDocumentIds')"
      />

      <DocumentItem
        header="Правовые источники"
        :documents="stepDto.stepLegalSourceIds"
        :options="getLegalSourceList"
        @add="addItem('stepLegalSourceIds')"
        @delete="(index) => deleteItem(index, 'stepLegalSourceIds')"
      />
    </div>

    <hr />
    <TimeAndCostForm
      v-model:duration="stepDto.duration"
      v-model:hour="stepDto.hour"
      v-model:minute="stepDto.minute"
      v-model:durationComment="stepDto.durationComment"
      v-model:cost="stepDto.cost"
      v-model:costComment="stepDto.costComment"
      :isHourFormat="stepDto.isHourFormat"
      @switchHandlerTime="switchHandTime"
    />

    <hr />
    <h3 class="custom-form__title">Шаги</h3>
    <ActionItems
      :actions="stepDto.stepActions"
      columnName="Шаг"
      btnName="Добавить шаги"
      @add="addAction"
      @delete="(index) => deleteItem(index, 'stepActions')"
    />

    <hr />
    <h3 class="custom-form__title">Видео</h3>
    <ActionItems
      :actions="stepDto.stepVideos"
      columnName="Название"
      :isWithLink="true"
      btnName="Добавить видео"
      @add="addVideos"
      @delete="(index) => deleteItem(index, 'stepVideos')"
    />
    <hr />
    <h3 class="custom-form__title">Полезные материалы</h3>
    <ActionItems
      :actions="stepDto.stepMaterials"
      columnName="Название"
      :isWithLink="true"
      btnName="Добавить"
      @add="addUsefulDocuments"
      @delete="(index) => deleteItem(index, 'stepMaterials')"
    />

    <h3 class="custom-form__title mt-10 mb-10">Дополнительная информация</h3>
    <TinyMceEditor v-model="stepDto.extraInfo" :init="tinyOptions" />
    <div class="custom-form-inputBlock">
      <p class="custom-form__label">Для чего нужен навигатор</p>
      <ComponentInput v-model="stepDto.purpose" mod="rectangular" />
    </div>
    <div class="custom-form__button-container">
      <ButtonComponent @click.prevent="updateStep" mod="gradient-bg" :disabled="v$.$invalid">Сохранить</ButtonComponent>
      <ButtonComponent @click="closeEditStepModal">Отменить</ButtonComponent>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';

  import TinyMceEditor from '@/components/tinymceeditor';

  import { NAVIGATOR_STEP_CARD_ACTIONS_NAME, NAVIGATOR_STEP_CARD_GETTERS_NAME } from '../../store/stepCards';
  import { OrganizationRoleId, TINY_OPTIONS } from '../../utils/constants';
  import ActionItems from '../operations/ActionItems.vue';
  import CardListItems from '../operations/CardListItems.vue';
  import DocumentItem from '../operations/DocumentItem.vue';
  import TimeAndCostForm from '../operations/TimeAndCostForm.vue';

  export default {
    name: 'EditStepModal',
    emits: ['updateStep', 'close'],
    components: {
      ComponentInput,
      TinyMceEditor,
      CardListItems,
      DocumentItem,
      TimeAndCostForm,
      ActionItems,
      ButtonComponent,
    },
    props: {
      stepData: Object,
    },
    data() {
      return {
        request: {
          ...Constants.paramsFullListDefault,
          typeIds: [Constants.organizationType.navigatorParticipant],
        },
        stepDto: {
          id: this.stepData.id,
          stepParticipants: this.stepData.stepParticipants ?? [],
          stepParticipants2: [],
          stepResultDocumentIds: this.stepData.stepResultDocumentIds ?? [],
          stepRequiredDocumentIds: this.stepData.stepRequiredDocumentIds ?? [],
          stepLegalSourceIds: this.stepData.stepLegalSourceIds ?? [],
          stepActions: this.stepData.stepActions ?? [],
          serviceUrl: this.stepData.serviceUrl ?? '',
          isHourFormat: this.stepData.isHourFormat,
          durationMins: this.stepData.durationMins ?? 0,
          duration: this.stepData.duration ?? 0,
          hour: this.stepData.hour ?? 0,
          minute: this.stepData.minute ?? 0,
          cost: this.stepData.cost ?? 0,
          durationComment: this.stepData.durationComment,
          costComment: this.stepData.costComment,
          extraInfo: this.stepData.extraInfo,
          isTemplate: false,
          stepVideos: this.stepData.stepVideos ?? [],
          stepMaterials: this.stepData.stepMaterials ?? [],
          purpose: this.stepData.purpose ?? '',
          templateId: this.stepData.templateId,
        },
        tinyOptions: { ...TINY_OPTIONS },
        participants: [],
        regulators: [],
      };
    },
    watch: {
      stepData: {
        handler() {
          this.stepDto.id = this.stepData.id ?? 0;
          this.stepDto.stepParticipants = this.stepData.stepParticipants;
          this.stepDto.stepResultDocumentIds = this.stepData.stepResultDocumentIds.map((id) => ({ id })) ?? [];
          this.stepDto.stepRequiredDocumentIds = this.stepData.stepRequiredDocumentIds.map((id) => ({ id })) ?? [];
          this.stepDto.stepLegalSourceIds = this.stepData.stepLegalSourceIds.map((id) => ({ id })) ?? [];
          this.stepDto.stepActions = this.stepData.stepActions ?? [];
          this.stepDto.serviceUrl = this.stepData.serviceUrl ?? '';
          this.stepDto.duration = this.stepData.duration ?? 0;
          this.stepDto.isHourFormat = this.stepData.isHourFormat;
          this.stepDto.hour = this.stepData.hour ?? 0;
          this.stepDto.minute = this.stepData.minute ?? 0;
          this.stepDto.cost = this.stepData.cost ?? 0;
          this.stepDto.durationComment = this.stepData.durationComment;
          this.stepDto.costComment = this.stepData.costComment;
          this.stepDto.extraInfo = this.stepData.extraInfo;
          this.stepDto.stepVideos = this.stepData.stepVideos ?? [];
          this.stepDto.stepMaterials = this.stepData.stepMaterials ?? [];
          this.stepDto.templateId = this.stepData.templateId;
        },
        deep: true,
      },
    },
    created() {
      this.fetchOrganizationList(this.request);
      this.fetchDocumentList(Constants.paramsFullListDefault);
      this.fetchLegalSourceList(Constants.paramsFullListDefault);
      this.loadCard();
    },

    methods: {
      ...mapActions({
        fetchOrganizationList: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getOrganizationList,
        fetchDocumentList: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getDocumentList,
        fetchLegalSourceList: NAVIGATOR_STEP_CARD_ACTIONS_NAME.getLegalSourceList,
      }),
      loadCard() {
        this.participants = this.stepDto.stepParticipants
          .filter((item) => item.organizationRoleId === OrganizationRoleId.PARTICIPANT)
          .sort((a, b) => a.orderNum - b.orderNum);
        this.regulators = this.stepDto.stepParticipants
          .filter((item) => item.organizationRoleId === OrganizationRoleId.REGULATOR)
          .sort((a, b) => a.orderNum - b.orderNum);

        this.stepDto.stepResultDocumentIds = this.stepDto.stepResultDocumentIds.map((id) => ({ id }));
        this.stepDto.stepRequiredDocumentIds = this.stepDto.stepRequiredDocumentIds.map((id) => ({ id }));
        this.stepDto.stepLegalSourceIds = this.stepDto.stepLegalSourceIds.map((id) => ({ id }));
      },
      addItem(type) {
        this.stepDto[type].push({ id: null });
      },
      deleteItem(index, type) {
        this.stepDto[type].splice(index, 1);
      },
      switchHandTime(value) {
        this.stepDto.isHourFormat = value;
        this.stepDto.minute = this.stepDto.hour = this.stepDto.durationMins = 0;
      },
      addRegulators() {
        this.regulators.push({
          organizationId: null,
          organizationRoleId: OrganizationRoleId.REGULATOR,
          orderNum: null,
          stepId: Number(this.stepDto.id),
        });
      },
      deleteRegulators(index) {
        this.regulators.splice(index, 1);
      },
      addParticipant() {
        this.participants.push({
          organizationId: null,
          organizationRoleId: OrganizationRoleId.PARTICIPANT,
          orderNum: null,
          stepId: Number(this.stepDto.id),
        });
      },
      deleteParticipant(index) {
        this.participants.splice(index, 1);
      },
      addAction() {
        this.stepDto.stepActions.push({
          orderNum: this.stepDto.stepActions.length + 1,
          action: '',
        });
      },
      addVideos() {
        this.stepDto.stepVideos.push({ orderNum: this.stepDto.stepVideos.length + 1, action: '', link: '' });
      },
      addUsefulDocuments() {
        this.stepDto.stepMaterials.push({ orderNum: this.stepDto.stepMaterials.length + 1, action: '', link: '' });
      },
      updateStep() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return;
        }
        this.stepDto.durationMins = this.stepDto.isHourFormat ? this.stepDto.hour * 60 : this.stepDto.minute;

        this.stepDto.stepParticipants = [
          ...this.regulators.filter(({ organizationId }) => organizationId != null),
          ...this.participants.filter(({ organizationId }) => organizationId != null),
        ];

        this.stepDto.stepResultDocumentIds = this.stepDto.stepResultDocumentIds
          .filter((item) => item.id != null)
          .map((item) => Number(item.id));
        this.stepDto.stepRequiredDocumentIds = this.stepDto.stepRequiredDocumentIds
          .filter((item) => item.id != null)
          .map((item) => Number(item.id));
        this.stepDto.stepLegalSourceIds = this.stepDto.stepLegalSourceIds
          .filter((item) => item.id != null)
          .map((item) => Number(item.id));
        this.$emit('updateStep', this.stepDto);
      },
      closeEditStepModal() {
        this.stepDto = null;
        this.$emit('close');
      },
    },
    computed: {
      ...mapGetters({
        getOrganizationList: NAVIGATOR_STEP_CARD_GETTERS_NAME.getListOrganizations,
        getDocumentList: NAVIGATOR_STEP_CARD_GETTERS_NAME.getListDocuments,
        getLegalSourceList: NAVIGATOR_STEP_CARD_GETTERS_NAME.getListLegalSources,
      }),
    },
    setup: () => ({ v$: useVuelidate() }),
  };
</script>

<style lang="scss">
  .tox-statusbar__branding,
  .tox-notification.tox-notification--in.tox-notification--warning {
    display: none;
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';

  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
  .table-container table.editStepModal-table {
    min-width: initial;
    width: 100%;
  }
</style>
