<template>
  <PageTitle title="Карточка сценария" subtitle="Всего сценариев" :subtitleNumber="getScenariosTotalItem" />
  <div class="container-page big">
    <div>
      <Tabs>
        <Tab name="Основная информация" :selected="true">
          <GeneralInfoScenarioTab
            v-model:cardName="card.name"
            v-model:cardScenarioProductIds="card.scenarioProductIds"
            v-model:cardTradingOperationId="card.tradingOperationId"
            v-model:cardDepartureCountryIds="card.departureCountryIds"
            v-model:cardDestinationCountryIds="card.destinationCountryIds"
            v-model:cardScenarioTransitCountryIds="card.scenarioTransitCountryIds"
            v-model:cardScenarioTransportIds="card.scenarioTransportIds"
            v-model:cardDescription="card.description"
            v-model:cardIsActive="card.isActive"
          />
        </Tab>
        <Tab name="Этапы">
          <EditStepScenarioTap
            :flatList="flatList"
            :card="card"
            @addStage="addStage"
            @selectStage="selectStage"
            @changeCheckbox="changeCheckbox"
            @addProcedure="addProcedure"
            @selectProcedure="selectProcedure"
            @addStep="addStep"
            @selectStep="selectStep"
            @deleteItem="deleteItem"
            @move="move"
            @recalcExpenses="recalcExpenses"
          />
        </Tab>
      </Tabs>
    </div>
    <div class="custom-form__button-container mt-32">
      <ButtonComponent @click="save" mod="gradient-bg" :disabled="v$.$invalid || disableButton">{{
        disableButton ? 'Сохранение' : 'Сохранить'
      }}</ButtonComponent>
      <ButtonComponent @click="close">Отменить</ButtonComponent>
    </div>
  </div>

  <ModalComponent v-model="elemSelectorModal" :width="950" :title="elemSelectorTitle" @close="closeElemSelector">
    <ScenarioElementSelector
      :selected-ids="selectedElemIds"
      :route="elementRoute"
      :exclude-ids="excludeElemIds"
      @select="updateElement"
      @close="closeElemSelector"
    ></ScenarioElementSelector>
  </ModalComponent>

  <!-- Окно оповещения -->
  <ModalInfo
    v-model="isOpenModalInfo"
    :titleModal="titleModal"
    :textModal="textModal"
    :modalIconName="modalIconName"
    @closeModal="closeModalInfo"
  />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import { maxLength, required } from '@vuelidate/validators';
  import { cloneDeep } from 'lodash';
  import moment from 'moment';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import Tabs from '@/common/components/redesigned/Tabs/Tab.vue';
  import Tab from '@/common/components/redesigned/Tabs/TabItem.vue';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';

  import ModalComponent from '@/components/modals/ModalComponent';

  import API from '../api/scenario';
  import EditStepScenarioTap from '../components/scenario/EditStepScenarioTap';
  import GeneralInfoScenarioTab from '../components/scenario/GeneralInfoScenarioTab.vue';
  import ScenarioElementSelector from '../components/ScenarioElementSelector';
  import { NAVIGATOR_SCENARIOS_ACTIONS_NAME, NAVIGATOR_SCENARIOS_GETTERS_NAME } from '../store/scenarios';
  import { MODAL_INFO_MESSAGES, PROCEDURE, STAGE, STEP } from '../utils/constants';

  export default {
    name: 'ScenarioCard',
    mixins: [modalInfo],
    components: {
      ScenarioElementSelector,
      ModalComponent,
      Tabs,
      Tab,
      ButtonComponent,
      GeneralInfoScenarioTab,
      PageTitle,
      ModalInfo,
      EditStepScenarioTap,
    },
    data() {
      return {
        id: this.$route.params.id,
        disableButton: false,
        card: {
          id: 0,
          name: '',
          description: '',
          tradingOperationId: null,
          departureCountryIds: [],
          destinationCountryIds: [],
          scenarioTransitCountryIds: [],
          scenarioProductIds: [],
          scenarioTransportIds: [],
          stages: [
            {
              ...STAGE,
              checkboxId: moment().toISOString(),
              procedures: [
                {
                  ...PROCEDURE,
                  checkboxId: moment().add(1, 'days').toISOString(),
                  steps: [
                    {
                      ...STEP,
                      checkboxId: moment().add(2, 'days').toISOString(),
                    },
                  ],
                },
              ],
            },
          ],
        },
        elemSelectorModal: false,
        currentElem: null,
        selectedElemIds: null,
        excludeElemIds: null,
        elementRoute: '',
      };
    },
    created() {
      this.loadCard();
      this.fetchScenarios();
    },
    methods: {
      ...mapActions({
        fetchScenarios: NAVIGATOR_SCENARIOS_ACTIONS_NAME.fetchScenarios,
      }),
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            const { data } = response;
            const card = cloneDeep(data);
            this.card = { ...card, duration: 0, hour: 0, minute: 0 };
            this.recalcExpenses();
          });
        }
      },

      save() {
        this.disableButton = true;
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.isOpenModalInfo = true;
          this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
          this.textModal = MODAL_INFO_MESSAGES.VALIDATION_ERROR;
          this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
          return;
        }
        if (!this.checkChildren()) {
          return;
        }
        this.flatList.forEach((item) => {
          if (typeof item.parent !== 'undefined') delete item.parent;
        });
        API.update(this.card)
          .then(() => {
            this.isOpenModalInfo = true;
            this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
            this.textModal = Constants.commonSuccess;
            this.modalIconName = Constants.MODAL_INFO_ICON.ACCEPT;
            if (this.card.id) {
              this.loadCard();
            } else {
              this.$router.push({ name: 'Scenarios' });
            }
          })
          .catch((error) => {
            this.isOpenModalInfo = true;
            this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
            this.textModal = MODAL_INFO_MESSAGES.SAVE_SCENARIO_ERROR;
            this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
            console.error(error?.response?.data);
          })
          .finally(() => {
            this.disableButton = false;
          });
      },
      changeCheckbox() {
        setTimeout(() => {
          this.recalcExpenses();
        });
      },
      addStage() {
        this.card.stages.push({
          ...STAGE,
          checkboxId: moment().toISOString(),
          name: `Этап ${this.card.stages.length + 1}`,
          procedures: [
            {
              ...PROCEDURE,
              checkboxId: moment().add(1, 'days').toISOString(),
              steps: [
                {
                  ...STEP,
                  checkboxId: moment().add(2, 'days').toISOString(),
                },
              ],
            },
          ],
        });
      },
      selectStage() {
        this.elementRoute = 'stage';
        this.currentElem = this.card;
        this.selectedElemIds = [];
        this.excludeElemIds = this.card.stages.map((x) => x.id);
        this.elemSelectorModal = true;
      },
      addProcedure(stage) {
        stage.procedures.push({
          ...PROCEDURE,
          checkboxId: moment().add(1, 'days').toISOString(),
          name: `Процедура ${stage.procedures.length + 1}`,
          orderNum: stage.procedures.length + 1,
          steps: [
            {
              ...STEP,
              checkboxId: moment().add(2, 'days').toISOString(),
            },
          ],
        });
      },
      selectProcedure(item) {
        this.elementRoute = 'procedure';
        this.currentElem = item;
        this.selectedElemIds = [];
        this.excludeElemIds = item.procedures.map((x) => x.id);
        this.elemSelectorModal = true;
      },
      addStep(proc) {
        proc.steps.push({
          ...STEP,
          checkboxId: moment().add(1, 'days').toISOString(),
          name: 'Операция ' + (proc.steps.length + 1),
          orderNum: proc.steps.length + 1,
        });
      },
      selectStep(item) {
        this.elementRoute = 'step';
        this.currentElem = item;
        this.selectedElemIds = [];
        this.excludeElemIds = item.steps.map((x) => x.id);
        this.elemSelectorModal = true;
      },
      updateElement(selection) {
        this.closeElemSelector();
        if (!selection?.length) return;
        API.getElemList(this.elementRoute, { ids: selection }).then((response) => {
          response.data.items.forEach((item, index) => {
            let destObject = { ...item };
            destObject.isTemplate = false;
            destObject.isNew = false;
            destObject.templateId = item.id;
            destObject.id = 0;
            switch (this.elementRoute) {
              case 'stage': {
                destObject.orderNum = this.card.stages.length + index + 1;
                this.card.stages.push(destObject);
                break;
              }
              case 'procedure': {
                destObject.orderNum = this.currentElem.procedures.length + index + 1;
                this.currentElem.procedures.push(destObject);
                break;
              }
              default:
                destObject.orderNum = this.currentElem.steps.length + index + 1;
                this.currentElem.steps.push(destObject);
                destObject.stepActions.forEach((x) => (x.id = 0));
                break;
            }
            this.recalcExpenses();
          });
        });
      },
      closeElemSelector() {
        this.elemSelectorModal = false;
      },

      deleteItem(item) {
        switch (item.nodeKind) {
          case 0:
            this.card.stages.splice(this.card.stages.indexOf(item), 1);
            this.refreshOrderNum(this.card);
            this.card.stages.forEach((item, index) => {
              item.orderNum = index;
            });
            break;
          case 1:
            item.parent.procedures.splice(item.parent.procedures.indexOf(item), 1);
            this.refreshOrderNum(item.parent.procedures);
            break;
          default:
            item.parent.steps.splice(item.parent.steps.indexOf(item), 1);
            this.refreshOrderNum(item.parent.steps);
            break;
        }
        this.recalcExpenses();
      },
      refreshOrderNum(array) {
        array.forEach((item, index) => {
          item.orderNum = index + 1;
        });
      },
      checkChildren() {
        if (!this.card.stages?.length) {
          this.isOpenModalInfo = true;
          this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
          this.textModal = MODAL_INFO_MESSAGES.SAVE_SCENARIO_ERROR_STEP;
          this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
          return false;
        }
        let isValid = true;
        this.card.stages.forEach((stage) => {
          if (isValid && !stage.procedures?.length) {
            this.isOpenModalInfo = true;
            this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
            this.textModal = `Этап "${stage.name}" не содержит ни одной процедуры.`;
            this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
            isValid = false;
            return;
          }
          stage.procedures.forEach((proc) => {
            if (isValid && !proc.steps?.length) {
              this.isOpenModalInfo = true;
              this.titleModal = MODAL_INFO_MESSAGES.SAVE_TITLE;
              this.textModal = `Процедура "${proc.name}" не содержит ни одной операции.`;
              this.modalIconName = Constants.MODAL_INFO_ICON.ERROR;
              isValid = false;
            }
          });
        });
        return isValid;
      },
      recalcExpenses() {
        this.card.duration = this.card.hour = this.card.minute = this.card.cost = 0;
        /**
         * ? дубликаты с префиксом stage нужны для подсчета данных с учетом и без учета чекбоксов "Параллельно" и "Необязательно",
         * ? т.к. для процедур должна отображаться сумма операций без учета, а для этапов - с учетом чекбоксов (также для итогового времени и суммы)
         */
        this.card.stageDuration = this.card.stageHour = this.card.stageMinute = this.card.stageCost = 0;

        this.card?.stages.forEach((stage) => {
          stage.duration = stage.hour = stage.minute = stage.cost = 0;
          stage.stageDuration = stage.stageHour = stage.stageMinute = stage.stageCost = 0;

          stage.procedures?.forEach((procedure) => {
            procedure.duration = procedure.hour = procedure.minute = procedure.cost = 0;
            procedure.stageDuration = procedure.stageHour = procedure.stageMinute = procedure.stageCost = 0;

            procedure.steps?.forEach((step) => {
              step.stageDuration = step.duration;
              step.stageCost = step.cost;

              step.hour = step.stageHour = step.isHourFormat ? step.durationMins / 60 : 0;
              step.minute = step.stageMinute = step.isHourFormat ? 0 : step.durationMins;

              this.recalcItem(step, procedure);
              this.recalcItemWithoutCheckbox(step, procedure);
            });

            this.recalcItem(procedure, stage);
            this.recalcItemWithoutCheckbox(procedure, stage);
          });
          stage.duration = stage.stageDuration;
          stage.hour = stage.stageHour;
          stage.minute = stage.stageMinute;
          stage.cost = stage.stageCost;

          this.recalcItem(stage, this.card);
          this.recalcItemWithoutCheckbox(stage, this.card);

          if (this.card.hour > 0 || this.card.minute > 0) {
            this.card.duration += 1;
          }
        });
        this.card.duration = this.card.stageDuration;
        this.card.hour = this.card.stageHour;
        this.card.minute = this.card.stageMinute;
        this.card.cost = this.card.stageCost;
      },
      /**
       * Переиспользуемая функция для расчета общего времени и стоимости этапа
       * @param {Object} item - элемент массива в родительском компоненте
       * @param {Object} parentItem - родительский компонент
       */
      recalcItem(item, parentItem) {
        if (!item.notNecessary && !item.parallel) {
          parentItem.stageDuration += Number(item.stageDuration);
          parentItem.stageHour += Number(item.stageHour);
          parentItem.stageMinute += Number(item.stageMinute);
          parentItem.stageCost += Number(item.stageCost);
        } else if (item.parallel && !item.notNecessary) {
          parentItem.stageCost += Number(item.stageCost);
        }

        if (parentItem.stageMinute > 59) {
          parentItem.stageHour += Math.floor(parentItem.stageMinute / 60);
          parentItem.stageMinute = parentItem.stageMinute % 60;
        }

        if (parentItem.stageHour > 23) {
          parentItem.stageDuration += Math.floor(parentItem.stageHour / 24);
          parentItem.stageHour = parentItem.stageHour % 24;
        }
      },

      /**
       * Переиспользуемая функция для расчета общего времени и стоимости этапа
       * без учета чекбоксов "необязательно" и "параллельно"
       * @param {Object} item - элемент массива в родительском компоненте
       * @param {Object} parentItem - родительский компонент
       */
      recalcItemWithoutCheckbox(item, parentItem) {
        parentItem.duration += Number(item.duration);
        parentItem.hour += Number(item.hour);
        parentItem.minute += Number(item.minute);
        parentItem.cost += Number(item.cost);

        if (parentItem.minute > 59) {
          parentItem.hour += Math.floor(parentItem.minute / 60);
          parentItem.minute = parentItem.minute % 60;
        }

        if (parentItem.hour > 23) {
          parentItem.duration += Math.floor(parentItem.hour / 24);
          parentItem.hour = parentItem.hour % 24;
        }
      },

      close() {
        this.$router.push({ name: 'Scenarios' });
      },
      checkDisabled(item, isUp) {
        let itemCollection = this.getParentCollection(item);

        if (itemCollection) {
          let itemIndex = itemCollection.indexOf(item);
          return (itemIndex == 0 && isUp) || (itemIndex == itemCollection.length - 1 && !isUp);
        }

        return false;
      },
      move(item, isUp) {
        if (this.checkDisabled(item, isUp)) return;

        const itemCollection = this.getParentCollection(item);
        if (itemCollection) {
          let itemIndex = itemCollection.indexOf(item);
          if (isUp) {
            itemCollection[itemIndex - 1].orderNum += 1;
          } else {
            itemCollection[itemIndex + 1].orderNum -= 1;
          }
          isUp ? (item.orderNum -= 1) : (item.orderNum += 1);
          itemCollection.splice(itemIndex, 1);
          isUp ? itemIndex-- : itemIndex++;
          itemCollection.splice(itemIndex, 0, item);
        }
      },
      getParentCollection(item) {
        switch (item.nodeKind) {
          case 0:
            return this.card.stages;
          case 1:
            return item.parent?.procedures;
          case 2:
            return item.parent?.steps;
          default:
            return null;
        }
      },
    },
    computed: {
      ...mapGetters({
        getScenariosTotalItem: NAVIGATOR_SCENARIOS_GETTERS_NAME.getScenariosTotalItem,
      }),
      flatList() {
        let list = [];
        this.card.stages.forEach((stage) => {
          stage.nodeKind = 0;
          list.push(stage);
          stage.procedures.forEach((proc) => {
            proc.nodeKind = 1;
            // proc.duration = typeof proc.duration === 'string' ? proc.duration.split('.')[0] : proc.duration;
            proc.parent = stage;
            list.push(proc);
            proc.steps.forEach((step) => {
              step.nodeKind = 2;
              step.parent = proc;
              // step.isHourFormat ? (step.hour = step.durationMins / 60) : (step.minute = step.durationMins);
              list.push(step);
            });
          });
        });
        return list;
      },
      elemSelectorTitle() {
        switch (this.elementRoute) {
          case 'procedure':
            return 'Выбор процедуры';
          case 'step':
            return 'Выбор операции';
          default:
            return 'Выбор этапа';
        }
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {
          name: {
            required,
            maxLength: maxLength(255),
          },
          scenarioProductIds: { required },
          tradingOperationId: { required },
          departureCountryIds: { required },
          destinationCountryIds: { required },
          scenarioTransportIds: { required },
        },
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/custom-form.scss';
</style>
