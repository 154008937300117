<template>
  <div class="modal__body-wrapper">
    <div class="mt-2">
      <div class="form-control-input-wrapper">
        <input v-model="request.name" placeholder="Найти..." class="form-control" v-on:input="search" />
      </div>
      <template v-if="page.items && !loading">
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th></th>
                <th>Название</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody v-if="page.items.length">
              <tr v-for="item in page.items" :key="item.id">
                <td>
                  <input type="checkbox" v-model="selection" :value="item.id" />
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
              </tr>
            </tbody>
            <tbody v-if="!page.items.length">
              <tr>
                <td class="text-center" colspan="4">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal__body-footer">
          <div class="modal__body-buttons">
            <ButtonStock class="mr-8" title="Выбрать" v-on:click="select" />
            <ButtonStock title="Отмена" v-on:click="close" />
          </div>
          <div class="modal__body-pagination" v-if="page.pagesTotal > 1">
            <Pagination
              :page-count="page.pagesTotal"
              :current-page="page.pageNumber"
              @change="onPageChange($event, false)"
              @more="onPageChange($event, true)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ProcAPI from '../api/procedure';
  import StageAPI from '../api/stage';
  import StepAPI from '../api/step';

  export default {
    name: 'ScenarioElementSelector',
    components: { ButtonStock },
    props: {
      route: {
        type: String,
        required: true,
      },
      selectedIds: Object,
      excludeIds: Object,
    },
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          excludeIds: this.excludeIds ?? [],
          name: null,
          isTemplate: true,
        },
        selection: this.selectedIds ?? [],
        elemType: this.route ?? '',
        loading: false,
        timerId: null,
      };
    },
    watch: {
      selectedIds: function () {
        this.selection = this.selectedIds;
      },
      route: function () {
        this.updateProperties();
      },
      excludeIds: function () {
        this.updateProperties();
      },
    },
    mounted() {
      let wrapper = document.querySelector('.form-control-input-wrapper');
      wrapper.addEventListener(
        'select',
        function (event) {
          event.stopImmediatePropagation();
        },
        true,
      );
      // проверка навешанных событий jquery на случай, если еще найдуться баги
      // $(document).ready(function(){
      // $(".form-control-input-wrapper input").bind("click mousedown", function(e) {
      //   console.log(e);
      // });
      // });
    },
    created() {
      this.loadPage();
    },
    methods: {
      updateProperties() {
        this.elemType = this.route;
        this.request.excludeIds = this.excludeIds ?? [];
        this.request.name = null;
        this.loadPage();
      },
      loadPage(append) {
        if (this.loading) return;
        this.loading = true;
        let api;
        switch (this.elemType) {
          case 'stage':
            api = StageAPI;
            break;
          case 'procedure':
            api = ProcAPI;
            break;
          case 'step':
            api = StepAPI;
            break;
          default:
            return;
        }
        api
          .search(this.request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      search() {
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
        this.timerId = setTimeout(() => {
          this.request.pageNumber = 1;
          this.loadPage();
        }, 1000);
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      select() {
        if (!this.selection?.length) {
          Constants.alert.fire('Выбор', 'Вы не выбрали ни одного элемента.', 'error');
          return;
        }
        this.$emit('select', this.selection);
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>
